import { __decorate } from "tslib";
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { AlertTheme } from '@component/Alert';
import { ConnectorErrors } from '@exception/connector-errors';
import PayU from '@subscription/components/Summary/drivers/PayU.vue';
import { TransactionDriver } from '@subscription/contracts';
let PayUSingleAccess = class PayUSingleAccess extends PayU {
    async beginTransaction() {
        if (!this.loading || !this.userData) {
            return;
        }
        this.isActing = true;
        try {
            const result = await this.transactions.paywall(this.pageId, TransactionDriver.PayU, {
                agreement: this.agreement
            }, this.userData, this.companyData, this.promoCode);
            if (!('redirect' in result)) {
                this.alertTheme = AlertTheme.Danger;
                this.errors = ['Nieudane połączenie z PayU'];
                return;
            }
            this.paymentUri = result.redirect;
            this.isWaitingForRedirect = true;
            this.$emit('update');
            setInterval(() => {
                if (this.timeToPayment === 1) {
                    this.redirectToPayU();
                    return;
                }
                this.timeToPayment--;
            }, 1000);
        }
        catch (e) {
            switch (e.status) {
                case ConnectorErrors.Validation:
                    this.alertTheme = AlertTheme.Warning;
                    this.errors = e.payload;
                    break;
                default:
                    this.alertTheme = AlertTheme.Danger;
                    this.errors = [e.message];
            }
            this.loading = false;
        }
    }
    updateAgreement() {
        this.ready = this.canGoToNext;
    }
    updateCompanyData() {
        this.ready = this.canGoToNext;
    }
};
__decorate([
    PropSync('isSubmitting', {
        type: Boolean,
        required: false,
        default: false
    })
], PayUSingleAccess.prototype, "loading", void 0);
__decorate([
    PropSync('isReady', {
        type: Boolean,
        required: false,
        default: false
    })
], PayUSingleAccess.prototype, "ready", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    })
], PayUSingleAccess.prototype, "pageId", void 0);
__decorate([
    Watch('loading')
], PayUSingleAccess.prototype, "beginTransaction", null);
__decorate([
    Watch('agreement')
], PayUSingleAccess.prototype, "updateAgreement", null);
__decorate([
    Watch('companyData', { deep: true })
], PayUSingleAccess.prototype, "updateCompanyData", null);
PayUSingleAccess = __decorate([
    Component({
        name: 'PayUSingleAccess'
    })
], PayUSingleAccess);
export { PayUSingleAccess };
export default PayUSingleAccess;
