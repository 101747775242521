import { __decorate } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import { AbstractModal } from '@component/Modal';
import { RadioBox } from '@component/RadioBox';
import { PromotionProductType, TransactionDriver } from '@subscription/contracts';
import { extractDriverFromOption, getOptionConfig } from '@subscription/components/PaymentMethod/PaymentMethod.helpers';
import { CompanyDataForm } from '@subscription/components/CompanyDataForm';
import { PayUSingleAccess } from '@subscription/components/Summary/drivers/PayUSingleAccess.vue';
import { PayURecursiveSingleAccess } from '@subscription/components/Summary/drivers/PayURecursiveSingleAccess.vue';
import PromoCode from '@subscription/components/PromoCode/PromoCode.vue';
import { Inject } from '@plugin/inversify';
import { SiteServiceType } from '@service/site';
export const driverProcessComponents = {
    [TransactionDriver.PayU]: PayUSingleAccess,
    [TransactionDriver.PayURecursive]: PayURecursiveSingleAccess
};
let BuySingleAccessModal = class BuySingleAccessModal extends AbstractModal {
    constructor() {
        super(...arguments);
        this.companyData = null;
        this.promoCode = null;
        this.isReady = false;
        this.isSubmitting = false;
        this.paymentMethodOption = null;
        this.step = 0;
        this.userData = null;
        this.promoCodesConfig = null;
        this.articleData = {
            id: this.payload.pageId,
            finalPrice: this.payload.price,
            kind: 'standard',
            type: PromotionProductType.Article
        };
        this.products = [this.articleData];
        this.availablePaymentMethods = [TransactionDriver.PayU, TransactionDriver.PayURecursive];
        this.lastStep = 0;
    }
    get canGoNext() {
        if (this.step === 0) {
            return this.paymentMethodOption === null;
        }
        return false;
    }
    get hasDiscount() {
        var _a;
        return !!((_a = this.articleData) === null || _a === void 0 ? void 0 : _a.discount);
    }
    get isLastStep() {
        return this.step === this.lastStep;
    }
    get me() {
        return this.$store.getters['profile/me'];
    }
    get pageTitle() {
        var _a;
        return (_a = this.payload.pageTitle) !== null && _a !== void 0 ? _a : '';
    }
    get userEmail() {
        var _a, _b;
        return (_b = (_a = this.me) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : '';
    }
    get meData() {
        if (!this.me) {
            return null;
        }
        return {
            ...this.me.companyData,
            firstName: this.me.firstName,
            lastName: this.me.lastName
        };
    }
    get paymentComponent() {
        return driverProcessComponents[this.paymentMethod || TransactionDriver.PayU];
    }
    get paymentOptions() {
        return this.availablePaymentMethods.map((driver, index) => getOptionConfig(`${index}`, driver));
    }
    get paymentMethod() {
        return extractDriverFromOption(this.paymentMethodOption);
    }
    get paymentMethodName() {
        if (!this.paymentMethod) {
            return '';
        }
        return [
            this.$t(`modules.subscription.paymentDrivers.${this.paymentMethod}.label`)
        ].join(' ');
    }
    changePaymentMethod() {
        this.goPrev();
    }
    goPrev() {
        this.step--;
    }
    goNext() {
        this.step++;
    }
    submit() {
        this.isSubmitting = true;
    }
    updateArticleData() {
        const article = this.products.find(p => p.type === PromotionProductType.Article);
        if (article) {
            this.articleData = article;
        }
    }
};
__decorate([
    Inject(SiteServiceType)
], BuySingleAccessModal.prototype, "siteService", void 0);
__decorate([
    Watch('products')
], BuySingleAccessModal.prototype, "updateArticleData", null);
BuySingleAccessModal = __decorate([
    Component({
        name: 'BuySingleAccessModal',
        components: {
            CompanyDataForm,
            PromoCode,
            RadioBox
        },
        created() {
            this.promoCodesConfig = this.siteService.getPromoCodesConfig();
        }
    })
], BuySingleAccessModal);
export { BuySingleAccessModal };
export default BuySingleAccessModal;
