import { __decorate } from "tslib";
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { ConnectorErrors } from '@exception/connector-errors';
import { ResourceActionFailed } from '@movecloser/front-core';
import { AlertTheme } from '@component/Alert';
import { ChecksBox } from '@component/ChecksBox';
import PayURecursive from '@subscription/components/Summary/drivers/PayURecursive.vue';
import { TransactionDriver } from '@subscription/contracts';
let PayURecursiveSingleAccess = class PayURecursiveSingleAccess extends PayURecursive {
    async beginTransaction() {
        var _a;
        if (!this.loading || !this.payU || !this.userData) {
            return;
        }
        this.isActing = true;
        this.errors = [];
        try {
            const tokenResult = await this.payU.tokenize('MULTI');
            if (tokenResult.status !== 'SUCCESS') {
                const formErrors = tokenResult.error.messages;
                throw new ResourceActionFailed('', ConnectorErrors.Validation, formErrors.map(m => m.message));
            }
            const token = tokenResult.body.token;
            this.$logger(token, 'debug');
            const result = await this.transactions.paywall(this.pageId, TransactionDriver.PayURecursive, {
                agreement: this.agreement,
                cardToken: token,
                redirectUri: this.redirectUri
            }, this.userData, this.companyData, this.promoCode);
            if (result.redirect) {
                window.location.href = result.redirect;
            }
            await this.$router.push({
                name: 'subscription.status',
                params: {
                    term: this.subscription.id,
                    transaction: `${(_a = this.subscription.transaction) === null || _a === void 0 ? void 0 : _a.token}`
                }
            });
        }
        catch (e) {
            const status = 'status' in e && !!e.status ? e.status : ConnectorErrors.Unknown;
            switch (status) {
                case ConnectorErrors.Conflict:
                    if (this.retriesCount && (e.payload.requiredAction === 'retryTransaction' ||
                        (e.payload.extraInfo && e.payload.extraInfo.code === '110'))) {
                        this.retriesCount--;
                        this.$logger('[PayURecursive]: Conflict retryTransaction');
                        // await this.registerNewTransaction()
                        this.$nextTick(() => {
                            this.beginTransaction();
                        });
                    }
                    else {
                        this.alertTheme = AlertTheme.Danger;
                        this.errors = [e.message];
                    }
                    break;
                case ConnectorErrors.Validation:
                    this.alertTheme = AlertTheme.Warning;
                    this.errors = e.payload;
                    break;
                default:
                    this.alertTheme = AlertTheme.Danger;
                    this.errors = Array.isArray(e.message) ? e.message : [e.message];
            }
            this.loading = false;
            this.isActing = false;
            this.$emit('update');
        }
    }
    updateAgreement() {
        this.ready = this.canProceed;
    }
};
__decorate([
    PropSync('isSubmitting', {
        type: Boolean,
        required: false,
        default: false
    })
], PayURecursiveSingleAccess.prototype, "loading", void 0);
__decorate([
    PropSync('isReady', {
        type: Boolean,
        required: false,
        default: false
    })
], PayURecursiveSingleAccess.prototype, "ready", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    })
], PayURecursiveSingleAccess.prototype, "pageId", void 0);
__decorate([
    Watch('loading')
], PayURecursiveSingleAccess.prototype, "beginTransaction", null);
__decorate([
    Watch('agreement')
], PayURecursiveSingleAccess.prototype, "updateAgreement", null);
PayURecursiveSingleAccess = __decorate([
    Component({
        name: 'PayURecursiveSingleAccess',
        components: { ChecksBox }
    })
], PayURecursiveSingleAccess);
export { PayURecursiveSingleAccess };
export default PayURecursiveSingleAccess;
